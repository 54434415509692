import React, { useState } from "react";
import Axios from "axios";
import jwtDecode from "jwt-decode";
import Table from "./Table";

function App() {
  const [key, setKey] = useState(null);
  const [secret, setSecret] = useState(null);
  const [token, setToken] = useState(null);
  const [sub, setSub] = useState(null);

  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(1);

  const [email, setEmail] = useState(null);
  const [mpan, setMpan] = useState(null);
  const [mprn, setMprn] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [address, setAddress] = useState(null);
  const [postcode, setPostcode] = useState(null);

  const [data, setData] = useState(null);

  const getData = async e => {
    e.preventDefault();
    const optionalParams = {};
    if (email) optionalParams.email = email;
    if (mpan) optionalParams.mpan = mpan;
    if (mprn) optionalParams.mprn = mprn;
    if (firstName) optionalParams.firstName = firstName;
    if (lastName) optionalParams.lastName = lastName;
    if (address) optionalParams.address = address;
    if (postcode) optionalParams.postcode = postcode;
    const res = await Axios.get(`https://api.core.green.energy/properties`, {
      params: {
        page,
        limit,
        ...optionalParams
      },
      headers: {
        "Authorization": `Bearer ${token}`
      }
    });
    if (res.data.success) {
      setData(res.data.data);
    } else {
      setData(null);
    }
  }
  const authoriseApp = async e => {
    e.preventDefault();
    if (key && secret) {
      try {
        const res = await Axios.post("https://api.core.green.energy/auth/service-account", { key, secret });
        if (res.data.success) {
          setToken(res.data.data.token);
          setSub(jwtDecode(res.data.data.token).sub)
        } else {
          setToken(null);
        }
      } catch (e) {
        setToken(null);
      }
    }
  }
  return (
    <div className="container-large">
      <div className="col-start-2 explorer-container">
        <div className="header-bar">
          <img
            alt="Green"
            src={`https://green.cdn.energy/branding/logo-r.svg`}
          />
          <form onSubmit={authoriseApp}>
            <input className="ui-input" type="text" placeholder="Key" onChange={e => setKey(e.currentTarget.value)} />
            <input className="ui-input" type="password" placeholder="Secret" onChange={e => setSecret(e.currentTarget.value)} />
            <button type="submit" className="ui-btn ui-btn-compact ui-btn-basic ui-btn-flat"><i className="fas fa-arrow-right"></i></button>
          </form>
        </div>
        <div className="results">
          {
            !token ? <div className="unauthorised">
              <i className="fas fa-exclamation-triangle colour-orange"></i>
              <p className="ui-title-three margin-top-15">Unauthorised</p>
              <p className="ui-title-five ui-title-sub margin-top-5">Please enter a valid key and secret</p>
            </div> : <div className="authorised">
                <div className="filter-bar">
                  <form className="col-1 gap-15" onSubmit={getData}>
                    <p className="ui-title-five margin-bottom-10">Pagination Settings</p>
                    <div>
                      <label className="ui-label margin-bottom-5">Limit</label>
                      <input required className="ui-input" type="number" min="1" max="100" value={limit} onChange={e => setLimit(e.currentTarget.value)} />
                    </div>
                    <div>
                      <label className="ui-label margin-bottom-5">Page</label>
                      <input required className="ui-input" type="number" min="1" value={page} onChange={e => setPage(e.currentTarget.value)} />
                    </div>
                    <p className="ui-title-five margin-bottom-10 margin-top-20">Account Filtering</p>
                    <div>
                      <label className="ui-label margin-bottom-5">Email</label>
                      <input className="ui-input" type="text" value={email} onChange={e => setEmail(e.currentTarget.value)} />
                    </div>
                    <div>
                      <label className="ui-label margin-bottom-5">MPAN</label>
                      <input className="ui-input" type="text" value={mpan} onChange={e => setMpan(e.currentTarget.value)} />
                    </div>
                    <div>
                      <label className="ui-label margin-bottom-5">MPRN</label>
                      <input className="ui-input" type="text" value={mprn} onChange={e => setMprn(e.currentTarget.value)} />
                    </div>
                    <div>
                      <label className="ui-label margin-bottom-5">Address</label>
                      <input className="ui-input" type="text" value={address} onChange={e => setAddress(e.currentTarget.value)} />
                    </div>
                    <div>
                      <label className="ui-label margin-bottom-5">Postcode</label>
                      <input className="ui-input" type="text" value={postcode} onChange={e => setPostcode(e.currentTarget.value)} />
                    </div>
                    <div>
                      <label className="ui-label margin-bottom-5">First Name</label>
                      <input className="ui-input" type="text" value={firstName} onChange={e => setFirstName(e.currentTarget.value)} />
                    </div>
                    <div>
                      <label className="ui-label margin-bottom-5">Last Name</label>
                      <input className="ui-input" type="text" value={lastName} onChange={e => setLastName(e.currentTarget.value)} />
                    </div>
                    <button type="submit" className="ui-btn ui-btn-compact ui-btn-primary ui-btn-flat">Get Data <i className="margin-left-5 fas fa-arrow-right"></i></button>
                  </form>
                </div>
                <div className="data">
                  {
                    data && data.length ? <Table data={data} /> : <div className="unauthorised">
                      <i className="fas fa-empty-set colour-blue"></i>
                      <p className="ui-title-three margin-top-15">No Data</p>
                      <p className="ui-title-five ui-title-sub margin-top-5">Utilise the filters to retrieve data</p>
                    </div>
                  }
                </div>
              </div>}
        </div>
        <div className="footer-bar">
          <a href="https://developer.green.energy" without rel="noopener noreferrer" target="_blank">View API documentation</a>
          <div className="status">
            <div className="status-circle" style={{ backgroundColor: token ? "#30AF5F" : "#E67F25" }} />
            <p>{token ? `Authorised as ${sub}` : "Unauthorised"}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
